import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import CustomBreadcrumbs from "./CustomBreadcrumbs";
import Spinner from "./Spinner";
import AddEditUserModal from "./AddEditUserModal";
import Toast from "./Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faToggleOn,
  faToggleOff,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function UsersList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { search: searchQuery },
      });
      setUsers(response.data.users);
    } catch (err) {
      setError("Failed to fetch users.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchQuery]);

  const toggleStatus = async (userId) => {
    try {
      await axios.patch(`${API_BASE_URL}/api/admin/users/${userId}/toggle-status`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchUsers(); // Refresh user list
    } catch {
      // alert("Failed to update user status.");
      showToast("Failed to update user status.", 'error');
    }
  };

  const handleDelete = (user) => {
    setDeleteId(user.user_id);
    setDeleteName(user.user_name);
    setShowDeleteModal(true);
  };

  const deleteUser = async (userId) => {
    // if (!window.confirm("Are you sure you want to delete this user?")) return;

    try {
      await axios.delete(`${API_BASE_URL}/api/admin/users/${deleteId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchUsers(); // Refresh user list
      showToast("User deleted successfully.", 'success');
    } catch {
      // alert("Failed to delete user.");
      showToast("Failed to delete user.", 'error');
    }
    setShowDeleteModal(false);
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const openAddModal = () => {
    setCurrentUser(null); // No user for add
    setShowModal(true);
  };

  const openEditModal = (user) => {
    setCurrentUser(user); // Set user for edit
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleSuccess = () => {
    fetchUsers(); // Refresh the user list after success
    closeModal();
    showToast("User added successfully.", 'success');
  };

  const handleFailure = () => {
    // fetchUsers();
    closeModal();
    showToast("Failed to save user.", 'error');
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen max-w-6xl mx-auto font-rubik">
      {/* <CustomBreadcrumbs /> */}
      <h1 className="text-3xl font-semibold mb-4">User Management</h1>
      <p className="text-gray-600 mb-6">Manage the user accounts.</p>
      <div className="flex justify-between mb-6">
        <input
          type="search"
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="border rounded-lg p-2 w-1/3"
        />
        <button
          onClick={openAddModal}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg"
        >
          Add User
        </button>
      </div>
      {loading ? (
        <Spinner text="Fetching users..." />
      ) : error ? (
        <div className="mb-4 p-4 text-center bg-red-100 text-red-800 border border-red-300 rounded-lg">
          <p className="text-sm">Error: {error}</p>
        </div>
      ) : (
        <table className="min-w-full bg-white shadow-md rounded-lg border">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Role</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <>
              <tr key={user.user_id} className="hover:bg-gray-100 border-t">
                <td className="px-4 py-2">{user.user_name}</td>
                <td className="px-4 py-2">{user.user_email}</td>
                {user.admin && (
                  <td className="px-4 py-2">Admin</td>
                )}
                {!user.admin && (
                  <td className="px-4 py-2">User</td>
                )}
                <td className="px-4 py-2">
                  <Link
                    to={`/users/user-logs/${user.user_id}`}
                    title="View Logs"
                    className="mr-2 px-2 py-1 rounded-full text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors"
                  >
                    <FontAwesomeIcon icon={faEye} />
                    {/* <span>View Logs</span> */}
                  </Link>
                  <button
                    onClick={() => openEditModal(user)}
                    title="Edit"
                    className="mr-2 px-2 py-1 rounded-full text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    {/* Edit */}
                  </button>
                  <button
                    onClick={() => toggleStatus(user.user_id)}
                    title={user.is_active ? "Disable" : "Enable"}
                    className="mr-2 px-2 py-1 rounded-full hover:bg-blue-100 hover:text-blue-800 transition-colors"
                  >
                    <FontAwesomeIcon
                      icon={user.is_active ? faToggleOn : faToggleOff}
                      className={user.is_active ? "text-blue-600" : "text-gray-400"}
                    />
                    {/* {user.is_active ? "Disable" : "Enable"} */}
                  </button>
                  {user.user_name !== 'Raj' && (
                    <button
                      onClick={() => handleDelete(user)}
                      title="Delete"
                      className="px-2 py-1 rounded-full text-blue-600 hover:bg-blue-100 hover:text-blue-800 transition-colors"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      {/* Delete */}
                    </button>
                  )}
                </td>
              </tr>

              {/* {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full text-center">
                    <h4 className="text-lg font-semibold mb-4">Confirm Deletion</h4>
                    <p className="text-gray-600 mb-6">Are you sure you want to delete the user "{user.user_name}"?</p>
                    <div className="flex justify-center space-x-4">
                      <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                        Confirm
                      </button>
                      <button onClick={closeDeleteModal} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
              </>
            ))}
          </tbody>
        </table>
      )}
      {showModal && (
        <AddEditUserModal
          user={currentUser}
          onClose={closeModal}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
        />
      )}
      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete the user '{deleteName}'?</p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={deleteUser}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Confirm
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </div>
  );
}

import React, { useState } from 'react';
import axios from 'axios';

export default function AddEditUserModal({ user, onClose, onSuccess, onFailure }) {
  const [formData, setFormData] = useState({
    user_name: user?.user_name || '',
    user_email: user?.user_email || '',
    password: '',
    admin: user?.admin ? 'Admin' : 'User',
  });
  const [loading, setLoading] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = user
        ? `${API_BASE_URL}/api/admin/users/${user.user_id}`
        : `${API_BASE_URL}/api/admin/users/add`;
      const method = user ? 'put' : 'post';

      await axios({
        method,
        url,
        headers: { Authorization: `Bearer ${token}` },
        data: formData,
      });
      onSuccess();
    } catch (error) {
      // alert('Failed to save user.');
      onFailure();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <h2 className="text-2xl font-semibold mb-4">
          {user ? 'Edit User' : 'Add User'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              name="user_name"
              value={formData.user_name}
              onChange={handleChange}
              disabled={user}
              placeholder="Name"
              required
              className="border p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              name="user_email"
              value={formData.user_email}
              onChange={handleChange}
              disabled={user}
              placeholder="Email"
              required
              className="border p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
          <input
            type="password"
            name="password"
            className="border p-2 w-full rounded"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          </div>
          <div className="mb-4">
            <select
              name="admin"
              value={formData.admin}
              onChange={handleChange}
              required
              className="border p-2 w-full rounded"
            >
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 rounded ${loading ? 'bg-gray-300' : 'bg-blue-600 text-white'}`}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from './CustomBreadcrumbs';
import Spinner from './Spinner';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import FlowSelectionModal from './FlowSelectionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const FlowsReport = () => {
  const [messageStats, setMessageStats] = useState([]);
  const [dateRange, setDateRange] = useState('this_month');
  // const [selectedFlow, setSelectedFlow] = useState('');
  const [selectedFlows, setSelectedFlows] = useState([]);
  const [flows, setFlows] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasQueried, setHasQueried] = useState(false);
  const [viewType, setViewType] = useState('cards');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);

  ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/flows?status=PUBLISHED`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch flows');
        const data = await response.json();
        setFlows(data.flows);
      } catch (error) {
        console.error('Error fetching flows:', error);
      }
    };
    fetchFlows();
  }, []);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setDateError('Start date cannot be after the end date.');
    } else {
      setDateError('');
    }
  }, [startDate, endDate]);

  const fetchReportData = async () => {
    if (dateError) return;
    setIsLoading(true);
    setHasQueried(true);
    try {
      let url = `${API_BASE_URL}/api/flow_reports?flow=${selectedFlows.join(',')}`;
      if (dateRange === 'custom_range' && startDate && endDate) {
        url += `&dateRange=custom_range&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url += `&dateRange=${dateRange}`;
      }
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch report data');
      const data = await response.json();
      setMessageStats(data.flow_stats);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isRunQueryDisabled = dateRange === 'custom_range' && (!startDate || !endDate || dateError);
  const chartHeight = messageStats.length > 0 ? 450 : 300;

  const openTemplateModal = () => setShowModal(true);
  const closeTemplateModal = () => setShowModal(false);

  const handleConfirmTemplates = (flows) => {
    setSelectedFlows(flows.map((t) => t.flow_name));
  };

  const setSelectedFlowsSummary = () => {
    if (selectedFlows.length === 0) return 'All Flows';
    if (selectedFlows.length === 1) return selectedFlows[0];
    return `${selectedFlows[0]} and ${selectedFlows.length - 1} more`;
  };

  return (
    <div className="min-h-screen bg-gray-50 font-rubik flex">
      {/* Side Panel */}
      <div className="w-1/5 p-4 bg-white rounded-lg shadow-md mr-6">
        <h3 className="text-xl font-semibold mb-4">Filters</h3>
        <label className="block mb-2 text-sm font-medium">Date Range</label>
        {/* <select
          className="p-2 border rounded-md w-full mb-4"
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="custom_range">Custom Range</option>
        </select> */}
        <div className="space-x-2 mb-4">
          <button
            className={`p-2 text-xs rounded-full ${dateRange === 'this_month' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
            onClick={() => {
              setDateRange('this_month');
            }}
          >
            This Month
          </button>
          <button
            className={`p-2 text-xs rounded-full ${dateRange === 'last_month' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
            onClick={() => {
              setDateRange('last_month');
            }}
          >
            Last Month
          </button>
          <button
            className={`p-2 text-xs rounded-full ${dateRange === 'custom_range' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
            onClick={() => {
              setDateRange('custom_range');
            }}
          >
            Custom
          </button>
        </div>

        {dateRange === "custom_range" && (
          <div>
            <label className="block mb-2 text-sm font-medium">Start Date:</label>
            <input className="p-2 border rounded-lg text-sm focus:outline-none w-full mb-4" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            {dateError && <p className="text-xs text-red-600 mb-2">{dateError}</p>}
            <label className="block mb-2 text-sm font-medium">End Date:</label>
            <input className="p-2 border rounded-lg text-sm focus:outline-none w-full mb-4" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        )}

        {/* <label className="block mb-2 text-sm font-medium">Template</label>
        <select
          className="p-2 border text-sm rounded-md w-full"
          value={selectedFlow}
          onChange={(e) => setSelectedFlow(e.target.value)}
        >
          <option value="">All Flows</option>
          {flows.map((flow) => (
            <option key={flow.id} value={flow.flow_name}>
              {flow.flow_name}
            </option>
          ))}
        </select> */}

        <div
          className="mt-2 inline-flex items-center px-3 py-1 border rounded-full text-sm cursor-pointer bg-gray-100 hover:bg-gray-200"
          onClick={openTemplateModal}
        >
          <span className="mr-2">Flows: {selectedFlows.length || 'None'}</span>
          <FontAwesomeIcon icon={faBars} className="text-gray-600" />
        </div>
        <p className="mt-2 text-xs text-gray-900">Selected: {setSelectedFlowsSummary()}</p>

        <button
          className={`mt-4 w-full px-4 py-2 text-sm font-semibold rounded-md shadow-sm ${isRunQueryDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
          onClick={fetchReportData}
          disabled={isRunQueryDisabled}
        >
          Run Query
        </button>

        {/* <div className="mt-6">
        <label className="block mb-2 font-medium">View Type</label>
          <select
            className="p-2 border rounded-md w-full"
            value={viewType}
            onChange={(e) => setViewType(e.target.value)}
          >
            <option value="chart">Bar Chart</option>
            <option value="cards">Success Rate Cards</option>
          </select>
        </div> */}
        <div className="mt-6">
          <label className="block mb-2 text-sm font-medium">View Type</label>
          <div className="space-x-2">
            <button
              className={`p-2 text-xs rounded-full ${viewType === 'chart' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
              onClick={() => {
                setViewType('chart');
              }}
            >
              Bar Chart
            </button>
            <button
              className={`p-2 text-xs rounded-full ${viewType === 'cards' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
              onClick={() => {
                setViewType('cards');
              }}
            >
              Success Rate Cards
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1">
        <CustomBreadcrumbs />
        <h2 className="text-3xl font-semibold mb-4">Flow Level Report</h2>
        <p className="text-gray-600 mb-6">Analyze flow activity based on selected filters.</p>

        {isLoading ? (
          <Spinner text="Fetching the report..." />
        ) : (
          <div>
            {hasQueried ? (
              messageStats.length > 0 ? (
                viewType === 'chart' ? (
                  <div className="bg-white shadow-md rounded-lg p-6 mb-8" style={{ height: `${chartHeight}px` }}>
                    <Bar
                      data={{
                        labels: messageStats.map((stat) => stat.flowName),
                        datasets: [
                          {
                            label: 'Messages Sent',
                            data: messageStats.map((stat) => stat.sentCount),
                            backgroundColor: '#60A5FA',
                          },
                          {
                            label: 'Messages Failed',
                            data: messageStats.map((stat) => stat.failedCount),
                            backgroundColor: '#F87171',
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        scales: {
                          x: { grid: { display: false }, ticks: { font: { size: 12 }, color: '#374151' } },
                          y: { beginAtZero: true, grid: { color: '#E5E7EB', borderDash: [5, 5] }, ticks: { font: { size: 12 }, color: '#374151' } },
                        },
                        plugins: {
                          legend: { labels: { font: { size: 12 }, color: '#374151' } },
                          tooltip: { backgroundColor: '#111827', titleColor: '#F9FAFB', bodyColor: '#F9FAFB' },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="grid gap-6">
                    {messageStats.map((stat) => (
                      <div key={stat.flowId} className="p-4 bg-white rounded-lg shadow-md">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-lg font-semibold text-gray-800">{stat.flowName}</h4>
                          <span
                            className={`px-2 py-1 text-xs font-semibold rounded-md ${
                              stat.successRate >= 100 ? 'bg-green-100 text-green-600' : stat.successRate >= 80 ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'
                            }`}
                          >
                            {stat.successRate.toFixed(2)}%
                          </span>
                        </div>
                        <div className="relative h-3 rounded-full bg-gray-200">
                          <div
                            className="absolute top-0 left-0 h-3 rounded-full"
                            style={{
                              width: `${Math.min(stat.successRate, 100)}%`,
                              backgroundColor: stat.successRate >= 100 ? '#34D399' : stat.successRate >= 80 ? '#FBBF24' : '#F87171',
                            }}
                          />
                        </div>
                        <div className="flex justify-between text-sm mt-2 text-gray-700">
                          <span>{`${stat.deliveredCount.toLocaleString()} sent / ${stat.goalCount.toLocaleString()} total`}</span>
                          <span>{stat.successRate >= 100 ? 'Sent to all successfully!' : ''}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <p>No data found for the selected filters.</p>
              )
            ) : (
              <p>Click "Run Query" to view the report.</p>
            )}
          </div>
        )}
      </div>

      <FlowSelectionModal
        isOpen={showModal}
        flows={flows}
        onClose={closeTemplateModal}
        onConfirm={handleConfirmTemplates}
      />
    </div>
  );
};

export default FlowsReport;
